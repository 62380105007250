import React from "react";
import { NavLink, useParams } from "react-router-dom";

//Styles
import styles from "./NoPermissionPricingPackageLink.module.scss";

//Images & Icons
import Logo from "../../assets/Logo/Logo";
import NoPermissionsImage from "../../assets/Images/NoPermissionsImage/NoPermissionsImage";

//Components
import Button from "../../components/UI/Button/Button";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
import EmptyState from "../../components/UI/EmptyState/EmptyState";

const NoPermissionPricingPackageLink = () => {
  let user = <LoggedInUser orgPage={true} />;

  let logo = (
    <div className={styles.logoDetails}>
      <Logo />
    </div>
  );

  let { organizationId } = useParams();

  let navLinkUrl = "/my-organizations";

  let navLinkUrlOrgSetting = `/${organizationId}/organization-settings/billing-and-payment`;

  let image = <NoPermissionsImage />;

  let content = (
    <div className="textCenter">
      <p>Your subscription is expired please upgrade</p>
      <NavLink className="text_decoration_none" to={navLinkUrlOrgSetting}>
        <Button id="upgradeBtn" classes={styles.upgradeBtn} variant="primary">
          Upgrade Now
        </Button>
      </NavLink>
      <NavLink className="text_decoration_none" to={navLinkUrl}>
        <Button id="backToOrgBtn" classes={styles.orgBtn} variant="secondary">
          Go to your organizations view
        </Button>
      </NavLink>
    </div>
  );

  return (
    <div>
      <HeaderTitle title={logo} rightContent={user} />
      <div className={styles.organizationPickerWrapper}>
        <div className={styles.wrap}>
          <EmptyState componentClass="positionCenter" image={image} content={content} />
        </div>
      </div>
    </div>
  );
};

export default NoPermissionPricingPackageLink;

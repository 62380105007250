import React from "react";
import { Link } from "react-router-dom";

//Style
import styles from "./CurrencyTextLink.module.scss";

const CurrencyTextLink = (props) => {
  return (
    <p className={`${styles.currencyTextWrapper} textCenter border-5 `}>
      Currently showing prices in {props.currency}. If you wish to change this, please click{" "}
      <Link
        className="tableLink primary_text_color"
        to={{
          pathname: "/" + props.organizationId + "/organization-settings/geo-settings",
        }}
      >
        here
      </Link>{" "}
      to update your organization settings.
    </p>
  );
};

export default CurrencyTextLink;

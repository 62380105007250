import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  listOfCampaigns: [],
  companyDetails: {},
  statuses: {},
  listOfResponses: [],
  isLoading: false,
  isLoaded: false,
  isTimelineLoading: false,
  isTimelineLoaded: false,
  error: "",
};

//#region FETCH
const fetchListOfCampaignsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchListCampaignsSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.listOfCampaigns];
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfCampaigns = list;
  return newState;
};

const fetchListOfCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchCompanyDetailsRequest = (state, action) => {
  return {
    ...state,
    companyDetails: {},
    isLoading: true,
    isLoaded: false,
  };
};

const fetchCompanyDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let company = { ...state.companyDetails };
  company = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.companyDetails = company;
  return newState;
};

const fetchCompanyDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchProspectsStatusesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchProspectsStatusesSuccess = (state, action) => {
  const newState = { ...state };
  let statuses = { ...state.statuses };
  statuses = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.statuses = statuses;
  return newState;
};

const fetchProspectsStatusesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchTimelineResponsesRequest = (state, action) => {
  return {
    ...state,
    isTimelineLoading: true,
    isTimelineLoaded: false,
  };
};

const fetchTimelineResponsesSuccess = (state, action) => {
  const newState = { ...state };
  let responses = [...state.listOfResponses];
  responses = action.result;
  newState.isTimelineLoaded = true;
  newState.isTimelineLoading = false;
  newState.listOfResponses = responses;
  return newState;
};

const fetchTimelineResponsesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isTimelineLoading = false;
  newState.isTimelineLoaded = true;
  return newState;
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_REQUEST:
      return fetchListOfCampaignsRequest(state, action);

    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED:
      return fetchListCampaignsSuccess(state, action);

    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_FAILED:
      return fetchListOfCampaignsFailed(state, action);

    case actionTypes.FETCH_COMPANY_DETAILS_REQUEST:
      return fetchCompanyDetailsRequest(state, action);

    case actionTypes.FETCH_COMPANY_DETAILS_SUCCEEDED:
      return fetchCompanyDetailsSuccess(state, action);

    case actionTypes.FETCH_COMPANY_DETAILS_FAILED:
      return fetchCompanyDetailsFailed(state, action);

    case actionTypes.FETCH_PROSPECTS_STATUSES_REQUEST:
      return fetchProspectsStatusesRequest(state, action);

    case actionTypes.FETCH_PROSPECTS_STATUSES_SUCCEEDED:
      return fetchProspectsStatusesSuccess(state, action);

    case actionTypes.FETCH_PROSPECTS_STATUSES_FAILED:
      return fetchProspectsStatusesFailed(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_REQUEST:
      return fetchTimelineResponsesRequest(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_SUCCEEDED:
      return fetchTimelineResponsesSuccess(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_FAILED:
      return fetchTimelineResponsesFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;

// #region FETCH
export const FETCH_SALES_DOCUMENTS_REQUEST = "FETCH_SALES_DOCUMENTS_REQUEST";
export const FETCH_SALES_DOCUMENTS_SUCCEEDED =
  "FETCH_SALES_DOCUMENTS_SUCCEEDED";
export const FETCH_SALES_DOCUMENTS_FAILED = "FETCH_SALES_DOCUMENTS_FAILED";

export const FETCH_SALES_DOCUMENT_REQUEST = "FETCH_SALES_DOCUMENT_REQUEST";
export const FETCH_SALES_DOCUMENT_SUCCEEDED = "FETCH_SALES_DOCUMENT_SUCCEEDED";
export const FETCH_SALES_DOCUMENT_FAILED = "FETCH_SALES_DOCUMENTS_FAILED";

export const FETCH_SALES_VIDEOS_DOCUMENT_REQUEST =
  "FETCH_SALES_VIDEOS_DOCUMENT_REQUEST";
export const FETCH_SALES_VIDEOS_DOCUMENT_SUCCEEDED =
  "FETCH_SALES_VIDEOS_DOCUMENT_SUCCEEDED";
export const FETCH_SALES_VIDEOS_DOCUMENT_FAILED =
  "FETCH_SALES_VIDEOS_DOCUMENT_FAILED";

export const FETCH_SALES_DOCUMENTS_IMAGES_REQUEST =
  "FETCH_SALES_DOCUMENTS_IMAGES_REQUEST";
export const FETCH_SALES_DOCUMENTS_IMAGES_SUCCEEDED =
  "FETCH_SALES_DOCUMENTS_IMAGES_SUCCEEDED";
export const FETCH_SALES_DOCUMENTS_IMAGES_FAILED =
  "FETCH_SALES_DOCUMENTS_IMAGES_FAILED";

export const FETCH_LIST_OF_TAGS_REQUEST = "FETCH_LIST_OF_TAGS_REQUEST";
export const FETCH_LIST_OF_TAGS_SUCCEEDED = "FETCH_LIST_OF_TAGS_SUCCEEDED";
export const FETCH_LIST_OF_TAGS_FAILED = "FETCH_LIST_OF_TAGS_FAILED";

export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
// #endregion

// #region ADD
export const ADD_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED =
  "ADD_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED";
export const ADD_SALES_DOCUMENTS_VIDEO_SUCCEEDED =
  "ADD_SALES_DOCUMENTS_VIDEO_SUCCEEDED";
export const ADD_SALES_DOCUMENTS_IMAGE_SUCCEEDED =
  "ADD_SALES_DOCUMENTS_IMAGE_SUCCEEDED";

export const ADD_SALES_DOCUMENTS_DOCUMENT_FAILED =
  "ADD_SALES_DOCUMENTS_DOCUMENT_FAILED";
export const ADD_SALES_DOCUMENTS_VIDEO_FAILED =
  "ADD_SALES_DOCUMENTS_VIDEO_FAILED";
export const ADD_SALES_DOCUMENTS_IMAGE_FAILED =
  "ADD_SALES_DOCUMENTS_IMAGE_FAILED";
// #endregion

// #region CHANGE
export const CHANGE_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED =
  "CHANGE_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED";
export const CHANGE_SALES_DOCUMENTS_VIDEO_SUCCEEDED =
  "CHANGE_SALES_DOCUMENTS_VIDEO_SUCCEEDED";
export const CHANGE_SALES_DOCUMENTS_IMAGE_SUCCEEDED =
  "CHANGE_SALES_DOCUMENTS_IMAGE_SUCCEEDED";

export const CHANGE_SALES_DOCUMENTS_DOCUMENT_FAILED =
  "CHANGE_SALES_DOCUMENTS_DOCUMENT_FAILED";
export const CHANGE_SALES_DOCUMENTS_VIDEO_FAILED =
  "CHANGE_SALES_DOCUMENTS_VIDEO_FAILED";
export const CHANGE_SALES_DOCUMENTS_IMAGE_FAILED =
  "CHANGE_SALES_DOCUMENTS_IMAGE_FAILED";
// #endregion

// #region REMOVE
export const REMOVE_SALES_DOCUMENTS_REQUEST = "REMOVE_SALES_DOCUMENTS_REQUEST";
export const REMOVE_SALES_DOCUMENTS_SUCCEEDED =
  "REMOVE_SALES_DOCUMENTS_SUCCEEDED";
export const REMOVE_SALES_DOCUMENTS_FAILED = "REMOVE_SALES_DOCUMENTS_FAILED";

export const REMOVE_SALES_DOCUMENTS_DOCUMENT_REQUEST =
  "REMOVE_SALES_DOCUMENTS_DOCUMENT_REQUEST";
export const REMOVE_SALES_DOCUMENTS_DOCUMENT_SUCCESS =
  "REMOVE_SALES_DOCUMENTS_DOCUMENT_SUCCESS";
export const REMOVE_SALES_DOCUMENTS_DOCUMENT_FAILED =
  "REMOVE_SALES_DOCUMENTS_DOCUMENT_FAILED";

export const REMOVE_SALES_DOCUMENTS_IMAGE_REQUEST =
  "REMOVE_SALES_DOCUMENTS_IMAGE_REQUEST";
export const REMOVE_SALES_DOCUMENTS_IMAGE_SUCCESS =
  "REMOVE_SALES_DOCUMENTS_IMAGE_SUCCESS";
export const REMOVE_SALES_DOCUMENTS_IMAGE_FAILED =
  "REMOVE_SALES_DOCUMENTS_IMAGE_FAILED";
// #endregion

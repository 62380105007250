import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  responseDetails: {},
  isLoading: false,
  isLoaded: false,
  error: "",
};

//#region FETCH
const fetchResponseDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchResponseDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let response = { ...state.responseDetails };
  response = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.responseDetails = response;
  return newState;
};

const fetchResponseDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESPONSE_DETAILS_REQUEST:
      return fetchResponseDetailsRequest(state, action);

    case actionTypes.FETCH_RESPONSE_DETAILS_SUCCEEDED:
      return fetchResponseDetailsSuccess(state, action);

    case actionTypes.FETCH_RESPONSE_DETAILS_FAILED:
      return fetchResponseDetailsFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;

export const DT_FETCH_REQUEST = "DT_FETCH_REQUEST";
export const DT_FETCH_SUCCEEDED = "DT_FETCH_SUCCEEDED";
export const DT_FETCH_FAILED = "DT_FETCH_FAILED";

export const DT_CHANGE_PAGE = "DT_CHANGE_PAGE";
export const DT_SORT_DATA = "DT_SORT_DATA";
export const DT_NUM_RECORDS = "DT_NUM_RECORDS";
export const DT_SEARCH_RECORDS = "DT_SEARCH_RECORDS";
export const DT_FILTER_RECORDS = "DT_FILTER_RECORDS";
export const DT_SET_FILTER = "DT_SET_FILTER";
export const DT_CLEAR_FILTER = "DT_CLEAR_FILTER";
export const DT_INITIATE = "DT_INITIATE";
export const DT_CLEAR_INITIATED = "DT_CLEAR_INITIATED";

export const DT_SET_SELECTED_ROW = "DT_SET_SELECTED_ROW";
export const DT_CLEAR_SELECTED_ROW = "DT_CLEAR_SELECTED_ROW";
export const DT_SET_SELECT_MULTI_ROWS = "DT_SET_SELECT_MULTI_ROWS";
export const DT_CLEAR_SELECT_MULTI_ROWS = "DT_CLEAR_SELECT_MULTI_ROWS";

export const DT_UPDATE_ROWS = "DT_UPDATE_ROWS";
